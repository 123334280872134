<template>
    <RightSideBar @close="$emit('close','view-file')" button-class="bg-dynamicBackBtn text-white" :submit="'Download'" :cancel="'No, Cancel'" :loading="isLoading" @submit="submit">
        <template v-slot:title>
            <div class="text-darkPurple flex gap-5 items-center cursor-pointer">
            <div class=""  @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> View File
            </div>
        </template>
        
        <div class="w-full">
            <iframe v-if="type === 'pdf'" :src="getPdfUrl(item.fileUrl)" width="100%" height="800px"></iframe>
            <!-- For Office Files (DOC, XLSX, PPT)  -->
            <iframe v-else-if="type === 'doc' || type === 'xlsx' || type === 'ppt'" :src="getUrlForOffice(item.fileUrl)" width="100%" height="100%"></iframe>
            <!-- For CSV (Rendering as Text) -->
            <pre v-else-if="type === 'csv'" class="csv-viewer">{{ csvContent }}</pre>
            <!-- For Images (PNG, JPG) -->
            <img v-else :src="item.fileUrl" alt="File preview" width="100%" />
        </div>
    </RightSideBar>
</template>

<script>
  import RightSideBar from "../RightSideBar";

  export default {
    name: "ViewFile",
    components: {
      RightSideBar
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
      return {
        fileType: "",
        csvContent: null,
        isLoading: false,
      };
    },
    computed: {
        type(){
            return this.fileType
        }
    },
    methods: {
        closeSidebar() {
            this.$emit('close','view-file');
        },
        getPdfUrl(fileUrl) {
            return `https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
        },
        getUrlForOffice(fileUrl) {
            return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`;
        },
        async getAndLoadCSV(url) {
            try {
                const response = await fetch(url);
                this.csvContent = await response.text(); // Store CSV data
            } catch (error) {
                console.error('Error loading CSV:', error);
            }
        },
        submit() {
            const link = document.createElement('a');
            link.href = this.item.fileUrl;
            link.download = this.item.fileUrl.split('/').pop(); // Extracted the filename from the URL
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.closeSidebar() 

            return this.$toasted.success(`File downloaded successfully.`,{duration: 6000});
        }
    },
    created() {
        const type = this.item.fileUrl.split('.').pop().toLowerCase(); 
        this.fileType = type;

        if(this.fileType === 'csv') {
            this.getAndLoadCSV(this.item.fileUrl);
        }
    },
  };
</script>

<style scoped>
.csv-viewer {
  white-space: pre-wrap;
}
</style>
